var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import Modal from '@declaration/components/UI/Modal.vue';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import renContactConstraints from '@shared/validator/constraints/renContactConstraints';
import { ValidationContext } from '@shared/validator/validationContext';
import { newValidator, validate } from '@shared/validator/validator';
const contract = namespace('contract');
const newContactInformation = () => ({
    landlinePhone: '',
    mobilePhone: '',
    email: '',
    details: '',
});
let ContactInformationModal = class ContactInformationModal extends Vue {
    constructor() {
        super(...arguments);
        this.contactInformationForm = newContactInformation();
        this.validator = null;
    }
    created() {
        this.validator = newValidator(this.contactInformationForm);
    }
    resetForm() {
        if (this.contactInformationForm) {
            if (!this.selectedContract) {
                this.contactInformationForm.landlinePhone = null;
                this.contactInformationForm.mobilePhone = null;
                this.contactInformationForm.email = null;
                this.contactInformationForm.details = null;
            }
            else {
                const { contact } = this.selectedContract;
                this.contactInformationForm.landlinePhone = contact.landlinePhone;
                this.contactInformationForm.mobilePhone = contact.mobilePhone;
                this.contactInformationForm.email = contact.email;
                this.contactInformationForm.details = '';
            }
        }
    }
    close() {
        this.$emit('close');
    }
    confirm() {
        this.$emit('confirm', this.contactInformationForm);
    }
    revealViolations() {
        this.validation.revealViolations();
    }
    get validation() {
        if (this.validator)
            validate(this.validator, this.contactInformationForm, renContactConstraints);
        return new ValidationContext(this.validator);
    }
    get isValid() {
        return this.validation.isValid;
    }
};
__decorate([
    contract.State('selectedContract')
], ContactInformationModal.prototype, "selectedContract", void 0);
ContactInformationModal = __decorate([
    Component({
        components: { UiButton, Modal },
        meta: {
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
        },
        props: {
            isDisplayed: { type: Boolean, required: true },
            isLoading: { type: Boolean, default: false },
        },
    })
], ContactInformationModal);
export default ContactInformationModal;
