import { render, staticRenderFns } from "./GarageDoorDescription.vue?vue&type=template&id=b71df320&scoped=true"
import script from "./GarageDoorDescription.vue?vue&type=script&lang=ts"
export * from "./GarageDoorDescription.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b71df320",
  null
  
)

export default component.exports