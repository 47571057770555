var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import { Step } from '@shared/claim/steps';
import { CompensationMode } from '@shared/types/api/claim';
import OtherArtisanConfirmationModal from '@declaration/components/declaration/pages/indemnisation/autre-artisan/otherArtisanConfirmationModal.vue';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
const layout = namespace('layout');
const counting = namespace('counting');
const breakInDamage = namespace('breakInDamage');
const claim = namespace('claim');
let CompensationRemainingWorks = class CompensationRemainingWorks extends Vue {
    constructor() {
        super(...arguments);
        this.options = CompensationMode;
        this.isSaving = false;
        this.selfRepairImage = require('@declaration/assets/images/compensation/selfRepair.svg');
        this.partnerArtisanImage = require('@declaration/assets/images/compensation/partnerArtisan.svg');
    }
    async chooseOption(chosenCompensationOption) {
        this.itemsToRepair.forEach((item) => {
            this.updateBreakInDamage({
                changeKey: item.id,
                data: {
                    pendingCompensationMode: chosenCompensationOption,
                },
            });
        });
        this.isSaving = true;
        try {
            await this.saveChanges({
                step: Step.CompensationRemainingWorks,
                payload: { chosenCompensationOption },
            });
        }
        catch (err) {
            this.$store.dispatch('error/add', 'Une erreur est survenue, merci de réessayer.');
        }
        this.isSaving = false;
    }
    get hasOtherArtisanWorks() {
        return this.itemsToBeRepairedByMyArtisan.length > 0;
    }
    get itemsToBeRepairedByMyArtisan() {
        // récupérer les DI pas réparé avec devis d'un autre artisan
        return this.breakInDamages
            .filter((breakInDamage) => breakInDamage.needsRepair &&
            breakInDamage.pendingCompensationMode === CompensationMode.CompensationModePecuniary)
            .map((breakInDamage) => {
            return {
                key: breakInDamage.id,
                label: breakInDamageTitle(breakInDamage, this.$tc.bind(this)),
            };
        });
    }
    get hasRemainingWorks() {
        return this.itemsToRepair.length > 0;
    }
    get itemsToRepair() {
        // récupérer les DI pas réparé (qui n'ont pas de devis d'un autre artisan)
        return this.breakInDamages.filter((breakInDamage) => breakInDamage.needsRepair && breakInDamage.pendingCompensationMode === null);
    }
    get itemsToRepairKeyAndLabel() {
        return this.itemsToRepair.map((breakInDamage) => {
            return {
                key: breakInDamage.id,
                label: breakInDamageTitle(breakInDamage, this.$tc.bind(this)),
            };
        });
    }
};
__decorate([
    layout.State('color')
], CompensationRemainingWorks.prototype, "color", void 0);
__decorate([
    layout.State('isMobile')
], CompensationRemainingWorks.prototype, "isMobile", void 0);
__decorate([
    layout.Getter('isDesktop')
], CompensationRemainingWorks.prototype, "isDesktop", void 0);
__decorate([
    counting.State('counting')
], CompensationRemainingWorks.prototype, "counting", void 0);
__decorate([
    breakInDamage.State('damages')
], CompensationRemainingWorks.prototype, "breakInDamages", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], CompensationRemainingWorks.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Action('saveChanges')
], CompensationRemainingWorks.prototype, "saveChanges", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], CompensationRemainingWorks.prototype, "updateAndSaveClaim", void 0);
CompensationRemainingWorks = __decorate([
    Component({
        components: {
            UiButton,
            OtherArtisanConfirmationModal,
        },
        meta: {
            close: quitClaimDeclarationCloseCallback,
            title: 'page.title.compensation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            goBack: null,
        },
    })
], CompensationRemainingWorks);
export default CompensationRemainingWorks;
