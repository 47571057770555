import { render, staticRenderFns } from "./RollingShutterDescription.vue?vue&type=template&id=66b8ec9e&scoped=true"
import script from "./RollingShutterDescription.vue?vue&type=script&lang=ts"
export * from "./RollingShutterDescription.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b8ec9e",
  null
  
)

export default component.exports