var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import DamageFields from '@declaration/components/damage/DamageFields.vue';
import { damageSizeChoiceValue } from '@declaration/types/externalDamage';
import { externalDamageFields, externalDamageTitle, } from '@shared/externalDamage/externalDamageFields';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
import { EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS, FENCE_TYPE_TRAD_KEYS, GATE_MATERIAL_TRAD_KEYS, LITTLE_GATE_SIZE_IMAGES_AND_TRAD_KEYS, SHUTTER_MATERIAL_TRAD_KEYS, SHUTTER_TYPE_IMAGES_AND_TRAD_KEYS, WINDOW_TYPE_IMAGES_AND_TRAD_KEYS, } from '@shared/constants/externalDamages';
import { GATE_FILL_STYLE_IMAGE_AND_TRAD_KEYS } from '@declaration/constants/externalDamages';
import { ExternalDamageSlug, } from '@shared/types/api/externalDamage';
import { ExclusionReason } from '@shared/types/api/refundDetail';
import { MATERIALS_TRAD_KEYS } from '@shared/types/api/breakInDamage';
import { GATE_HEIGHT_TRAD_KEYS, GATE_WIDTH_TRAD_KEYS, } from '@shared/constants/breakInDamages/GateConstants';
import { ExternalDamageComponent } from '@declaration/components/externalDamage/ExternalDamageComponent';
import { GARAGE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES } from '@shared/constants/breakInDamages/GarageDoorConstants';
import { TILE_DISPLACEMENT_COUNT_TRAD_KEYS } from '@shared/constants/externalDamages/TileDisplacementConstants';
import { GUTTER_MATERIAL_TRAD_KEYS } from '@shared/constants/externalDamages/GutterConstants';
const externalDamage = namespace('externalDamage');
const layout = namespace('layout');
let ExternalDamageCard = class ExternalDamageCard extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.isDeleting = false;
    }
    onDelete() {
        if (this.confirmDelete !== null) {
            this.confirmDelete(this.externalDamage.id, this.deleteCallback);
        }
    }
    async deleteCallback() {
        this.isDeleting = true;
        if (this.externalDamage.id) {
            this.deleteExternalDamage();
            await this.saveExternalDamage();
        }
        else {
            await this.deletePlaceholder();
        }
        this.isDeleting = false;
    }
    get isComplete() {
        return !!this.externalDamage.id;
    }
    get title() {
        return externalDamageTitle(this.externalDamage, this.damageOrigin, this.$tc.bind(this));
    }
    get modelFields() {
        // Broken external damage typing
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return externalDamageFields(this.externalDamage, {
            edition: false,
            type: this.typeTrigram,
            projection: ModelFieldsProjection.Declaration,
            translate: this.$tc.bind(this),
            stormSnowHailDamageCauseType: 'causeType' in this.qualification ? this.qualification.causeType : null,
        });
    }
    get isExcludedByGuarantees() {
        return this.externalDamage.exclusionReason === ExclusionReason.ContractGuarantees;
    }
    get descriptionTradkeys() {
        var _a, _b, _c, _d, _e, _f;
        const descriptions = [];
        if (!!this.externalDamage.localizationSlug &&
            this.externalDamage.localizationSlug !==
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.externalDamage.slug) {
            descriptions.push({
                key: 'claim.declaration.external_damages.card.localization',
                value: EXTERNAL_DAMAGE_LOCALIZATION_TRAD_KEYS[this.externalDamage.localizationSlug],
            });
        }
        if (this.externalDamage.slug === ExternalDamageSlug.GarageDoor) {
            descriptions.push(...[
                {
                    key: 'claim.declaration.external_damages.card.garage_door.type',
                    value: this.externalDamage.doorType
                        ? GARAGE_DOOR_TYPE_TRAD_KEYS_AND_IMAGES[this.externalDamage.doorType].sumupTradKey
                        : '-',
                },
                {
                    key: 'claim.declaration.external_damages.form.garage_door.width.title',
                    value: this.externalDamage.isWidthGreaterThanStandard
                        ? 'claim.declaration.external_damages.form.garage_door.width.greater'
                        : 'claim.declaration.external_damages.form.garage_door.width.lower',
                },
                {
                    key: 'claim.declaration.external_damages.card.garage_door.opening.sumup',
                    value: this.externalDamage.isOpeningMotorized
                        ? 'claim.declaration.external_damages.form.garage_door.opening.motorized'
                        : 'claim.declaration.external_damages.form.garage_door.opening.manual',
                },
            ]);
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.TileDisplacement) {
            descriptions.push(...[
                {
                    key: 'claim.declaration.external_damages.card.tile_displacement.count',
                    value: this.externalDamage.tileCount
                        ? TILE_DISPLACEMENT_COUNT_TRAD_KEYS[this.externalDamage.tileCount].sumupTradKey
                        : '-',
                },
            ]);
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.Chimney) {
            descriptions.push(...[
                {
                    key: 'claim.declaration.external_damages.card.chimney.count',
                    value: this.externalDamage.damagedCapsCount
                        ? this.externalDamage.damagedCapsCount.toString()
                        : '-',
                },
                {
                    key: 'claim.declaration.external_damages.card.chimney.stack',
                    value: this.externalDamage.isStackDamaged
                        ? 'question.answer.yes'
                        : 'question.answer.no',
                },
            ]);
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.Antenna) {
            descriptions.push(...[
                {
                    key: 'claim.declaration.external_damages.card.antenna.count',
                    value: this.externalDamage.antennaCount
                        ? this.externalDamage.antennaCount.toString()
                        : '0',
                },
            ]);
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.Gutter) {
            if (this.externalDamage.horizontalPartLength &&
                this.externalDamage.horizontalPartLength > 0) {
                descriptions.push({
                    key: 'claim.declaration.external_damages.card.gutter.horizontal',
                    value: `${this.externalDamage.horizontalPartLength} ${this.$tc('claim.declaration.external_damages.card.gutter.length_unit')}${this.externalDamage.horizontalPartMaterial
                        ? ` (${this.$tc(GUTTER_MATERIAL_TRAD_KEYS[this.externalDamage.horizontalPartMaterial])})`
                        : ''}`,
                });
            }
            if (this.externalDamage.verticalPartLength && this.externalDamage.verticalPartLength > 0) {
                descriptions.push({
                    key: 'claim.declaration.external_damages.card.gutter.vertical',
                    value: `${this.externalDamage.verticalPartLength} ${this.$tc('claim.declaration.external_damages.card.gutter.length_unit')}${this.externalDamage.verticalPartMaterial
                        ? ` (${this.$tc(GUTTER_MATERIAL_TRAD_KEYS[this.externalDamage.verticalPartMaterial])})`
                        : ''}`,
                });
            }
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.Fence) {
            descriptions.push({
                key: 'claim.declaration.external_damages.card.fence.type',
                value: this.externalDamage.fenceType && this.damageOrigin
                    ? this.$tc(FENCE_TYPE_TRAD_KEYS[this.damageOrigin][this.externalDamage.fenceType])
                    : '-',
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.fence.length',
                value: `${((_a = this.externalDamage.fenceLength) !== null && _a !== void 0 ? _a : 0).toString()} m`,
            });
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.Shutters) {
            descriptions.push({
                key: 'claim.declaration.external_damages.card.shutters.type',
                value: this.externalDamage.shutterType
                    ? this.$tc(SHUTTER_TYPE_IMAGES_AND_TRAD_KEYS[this.externalDamage.shutterType].tradKey)
                    : '-',
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.shutters.count',
                value: this.externalDamage.shutterCount ? this.externalDamage.shutterCount.toString() : '-',
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.shutters.material',
                value: this.externalDamage.material
                    ? this.$tc(SHUTTER_MATERIAL_TRAD_KEYS[this.externalDamage.material])
                    : '-',
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.shutters.width',
                value: this.externalDamage.isWidthGreaterThanStandard
                    ? this.$tc('claim.declaration.external_damages.form.shutters.width.greater')
                    : this.$tc('claim.declaration.external_damages.form.shutters.width.lower'),
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.shutters.opening',
                value: this.externalDamage.isOpeningMotorized
                    ? this.$tc('claim.declaration.external_damages.form.shutters.opening.motorized')
                    : this.$tc('claim.declaration.external_damages.form.shutters.opening.manual'),
            });
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.Window) {
            descriptions.push({
                key: 'claim.declaration.external_damages.card.window.type',
                value: this.externalDamage.windowType
                    ? this.$tc(WINDOW_TYPE_IMAGES_AND_TRAD_KEYS[this.externalDamage.windowType].tradKey)
                    : '-',
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.window.count',
                value: this.externalDamage.windowCount ? this.externalDamage.windowCount.toString() : '-',
            });
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.FrontWall) {
            descriptions.push({
                key: 'claim.declaration.external_damages.card.front_wall.wideness',
                value: `${((_b = this.externalDamage.frontWallWideness) !== null && _b !== void 0 ? _b : 0).toString()} m`,
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.front_wall.floor_count',
                value: this.externalDamage.frontWallFloorCount
                    ? this.externalDamage.frontWallFloorCount.toString()
                    : '-',
            });
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.Awning) {
            // To refactor with gate when migrating to the field
            const awningWidth = (_c = this.externalDamage.width) !== null && _c !== void 0 ? _c : 0;
            const awningLength = (_d = this.externalDamage.overhangLength) !== null && _d !== void 0 ? _d : 0;
            const currentWidth = this.awningTypes.find((awningType) => awningType.standardWidth === awningWidth);
            descriptions.push({
                key: 'claim.declaration.external_damages.card.awning.opening',
                value: this.externalDamage.isOpeningMotorized
                    ? this.$tc('claim.declaration.external_damages.form.awning.opening.motorized')
                    : this.$tc('claim.declaration.external_damages.form.awning.opening.manual'),
            });
            if (currentWidth) {
                const previousWidths = this.awningTypes
                    .map((awningType) => awningType.standardWidth)
                    .filter((width) => width < awningWidth)
                    .sort((a, b) => b - a);
                descriptions.push({
                    key: 'claim.declaration.external_damages.card.awning.dimensions.width',
                    value: damageSizeChoiceValue(this.$tc.bind(this), awningWidth, previousWidths.length > 0 ? previousWidths[0] : 0).label,
                });
                const currentLength = this.awningTypes.find((awningType) => awningType.standardWidth === awningWidth &&
                    awningType.overhangStandardLength === awningLength);
                if (currentLength) {
                    const previousLength = this.awningTypes
                        .filter((awningType) => awningType.standardWidth === awningWidth)
                        .map((awningType) => awningType.overhangStandardLength)
                        .filter((length) => length < awningLength)
                        .sort((a, b) => b - a);
                    descriptions.push({
                        key: 'claim.declaration.external_damages.card.awning.dimensions.length',
                        value: damageSizeChoiceValue(this.$tc.bind(this), awningLength, previousLength.length > 0 ? previousLength[0] : 0).label,
                    });
                }
                else {
                    descriptions.push({
                        key: 'claim.declaration.external_damages.card.awning.dimensions.length',
                        value: damageSizeChoiceValue(this.$tc.bind(this), awningLength - 1).label,
                    });
                }
            }
            else {
                descriptions.push({
                    key: 'claim.declaration.external_damages.card.awning.dimensions.width',
                    value: damageSizeChoiceValue(this.$tc.bind(this), awningWidth - 1).label,
                });
                descriptions.push({
                    key: 'claim.declaration.external_damages.card.awning.dimensions.length',
                    value: this.externalDamage.overhangLength === 1
                        ? damageSizeChoiceValue(this.$tc.bind(this), 1, 0).label
                        : damageSizeChoiceValue(this.$tc.bind(this), 1).label,
                });
            }
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.Gate) {
            descriptions.push({
                key: 'claim.declaration.external_damages.card.gate.type',
                value: this.externalDamage.type
                    ? this.$tc(GATE_FILL_STYLE_IMAGE_AND_TRAD_KEYS[this.externalDamage.type].tradKey)
                    : '-',
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.gate.panel_fill_style',
                value: this.externalDamage.panelFillStyle
                    ? this.$tc(GATE_FILL_STYLE_IMAGE_AND_TRAD_KEYS[this.externalDamage.panelFillStyle].tradKey)
                    : '-',
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.gate.material',
                value: this.externalDamage.material
                    ? this.$tc(GATE_MATERIAL_TRAD_KEYS[this.externalDamage.material])
                    : '-',
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.gate.width',
                value: this.externalDamage.widthSlug
                    ? this.$tc((_e = GATE_WIDTH_TRAD_KEYS[this.externalDamage.widthSlug]) !== null && _e !== void 0 ? _e : '-')
                    : '-',
            });
            descriptions.push({
                key: 'claim.declaration.external_damages.card.gate.height',
                value: this.externalDamage.heightSlug
                    ? this.$tc((_f = GATE_HEIGHT_TRAD_KEYS[this.externalDamage.heightSlug]) !== null && _f !== void 0 ? _f : '-')
                    : '-',
            });
        }
        else if (this.externalDamage.slug === ExternalDamageSlug.LittleGate) {
            descriptions.push({
                key: 'model.external_damage.material',
                value: this.externalDamage.material
                    ? this.$tc(MATERIALS_TRAD_KEYS[this.externalDamage.material])
                    : '-',
            });
            descriptions.push({
                key: 'model.external_damage.height',
                value: this.externalDamage.size
                    ? this.$tc(LITTLE_GATE_SIZE_IMAGES_AND_TRAD_KEYS[this.externalDamage.size].tradKey)
                    : '-',
            });
        }
        // claim.declaration.external_damages.card.gutter.horizontal
        return descriptions;
    }
};
__decorate([
    externalDamage.Action('deletePlaceholder')
], ExternalDamageCard.prototype, "deletePlaceholder", void 0);
__decorate([
    externalDamage.State('awningTypes')
], ExternalDamageCard.prototype, "awningTypes", void 0);
__decorate([
    externalDamage.State('gateTypes')
], ExternalDamageCard.prototype, "gateAllowanceTypes", void 0);
__decorate([
    layout.State('color')
], ExternalDamageCard.prototype, "color", void 0);
ExternalDamageCard = __decorate([
    Component({
        props: {
            canModify: {
                type: Boolean,
                default: true,
            },
            confirmDelete: {
                type: Function,
                default: null,
            },
        },
        components: {
            DamageFields,
            UiButton,
        },
    })
], ExternalDamageCard);
export default ExternalDamageCard;
