var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import DamageCard from '@declaration/components/UI/DamageCard.vue';
import GlassDamageModal from '@declaration/components/glassDamage/GlassDamageModal.vue';
import { glassDamageTitle, glassDamageFields } from '@shared/glassDamage/glassDamageFields';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
const glassDamage = namespace('glassDamage');
const settings = namespace('settings');
const qualification = namespace('qualification');
let GlassDamageList = class GlassDamageList extends Vue {
    constructor() {
        super(...arguments);
        this.isGlassDamageModalOpen = false;
        this.selectedchangeKey = null;
    }
    get roomDamagesCountThreshold() {
        return this.setting('bdgRoomDamagesCountThresholdBeforeExpertise');
    }
    get canAddNewGlassDamage() {
        return this.glassDamages.length < this.roomDamagesCountThreshold;
    }
    selectGlassDamageAndOpenModal(glassDamage) {
        this.selectedchangeKey = glassDamage.id;
        this.isGlassDamageModalOpen = true;
    }
    addNewGlassDamage() {
        this.selectedchangeKey = null;
        this.isGlassDamageModalOpen = true;
    }
    closeModal() {
        this.resetChange(this.selectedchangeKey);
        this.isGlassDamageModalOpen = false;
    }
    confirmDelete(glassDamageIri, deleteCallback) {
        if (glassDamageIri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc('claim.damage.break_in_damages.form.delete.modal.text'),
                cancelText: this.$tc('claim.damage.break_in_damages.form.delete.modal.cancel'),
                confirmText: this.$tc('claim.damage.break_in_damages.form.delete.modal.confirm'),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    isComplete(damage) {
        return !!damage.id;
    }
    modelFields(damage) {
        return glassDamageFields(damage, {
            edition: false,
            projection: ModelFieldsProjection.Declaration,
            translate: this.$tc.bind(this),
            type: this.typeTrigram,
        });
    }
    deleteDamageHandler(glassDamage) {
        this.damage = glassDamage;
        this.confirmDelete(glassDamage.id, this.deleteGlass);
    }
    async deleteGlass() {
        await this.delete(this.damage.id);
        await this.saveChanges();
    }
    title(damage, index) {
        const positionSuffix = this.getGlassDamagePositionSuffix(damage.type, index);
        return (glassDamageTitle(damage, this.$tc.bind(this)) + (positionSuffix ? ` ${positionSuffix}` : ''));
    }
};
__decorate([
    glassDamage.State('damages')
], GlassDamageList.prototype, "glassDamages", void 0);
__decorate([
    glassDamage.Action('deleteDamage')
], GlassDamageList.prototype, "delete", void 0);
__decorate([
    glassDamage.Action('saveChanges')
], GlassDamageList.prototype, "saveChanges", void 0);
__decorate([
    glassDamage.Action('resetChanges')
], GlassDamageList.prototype, "resetChange", void 0);
__decorate([
    glassDamage.Getter('getGlassDamagePositionSuffix')
], GlassDamageList.prototype, "getGlassDamagePositionSuffix", void 0);
__decorate([
    settings.Getter('setting')
], GlassDamageList.prototype, "setting", void 0);
__decorate([
    qualification.State('typeTrigram')
], GlassDamageList.prototype, "typeTrigram", void 0);
GlassDamageList = __decorate([
    Component({
        components: { GlassDamageModal, DamageCard },
        props: {
            addButtonOutlined: {
                type: Boolean,
                default: false,
            },
        },
    })
], GlassDamageList);
export default GlassDamageList;
