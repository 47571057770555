var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import DamageCard from '@declaration/components/UI/DamageCard.vue';
import { equipmentFields, equipmentTitle } from '@shared/equipment/equipmentFields';
import EquipmentModal from '@declaration/components/equipment/EquipmentModal.vue';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
const equipment = namespace('equipment');
const qualification = namespace('qualification');
let EquipmentList = class EquipmentList extends Vue {
    constructor() {
        super(...arguments);
        this.isEquipmentModalOpen = false;
        this.selectedchangeKey = null;
        this.isDeleting = false;
    }
    selectEquipmentAndOpenModal(equipment) {
        this.selectedchangeKey = equipment.id;
        this.isEquipmentModalOpen = true;
    }
    addNewEquipment() {
        this.selectedchangeKey = null;
        this.isEquipmentModalOpen = true;
    }
    closeModal() {
        this.isEquipmentModalOpen = false;
    }
    confirmDelete(equipmentIri, deleteCallback) {
        if (equipmentIri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc('claim.damage.equipments.form.delete.modal.text'),
                cancelText: this.$tc('claim.damage.equipments.form.delete.modal.cancel'),
                confirmText: this.$tc('claim.damage.equipments.form.delete.modal.confirm'),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    isComplete(equipment) {
        return !!equipment.id;
    }
    deleteDamageHandler(equipmentDamage) {
        this.equipment = equipmentDamage;
        this.confirmDelete(equipmentDamage.id, this.deleteEquipment);
    }
    async deleteEquipment() {
        await this.delete(this.equipment.id);
        await this.saveChanges();
    }
    modelFields(damage) {
        return equipmentFields(damage, {
            edition: false,
            projection: ModelFieldsProjection.Declaration,
            translate: this.$tc.bind(this),
            type: this.typeTrigram,
        });
    }
    get tradKeys() {
        return {
            card: {
                addDetails: 'claim.declaration.equipments.add_details',
            },
        };
    }
    title(damage, index) {
        const positionSuffix = this.getEquipmentDamagePositionSuffix(damage.name, index);
        return (equipmentTitle(damage, this.$tc.bind(this)) + (positionSuffix ? ` ${positionSuffix}` : ''));
    }
};
__decorate([
    equipment.Action('setEquipmentInEditionIndex')
], EquipmentList.prototype, "setEquipmentInEditionIndex", void 0);
__decorate([
    equipment.State('damages')
], EquipmentList.prototype, "equipments", void 0);
__decorate([
    equipment.Action('deleteDamage')
], EquipmentList.prototype, "delete", void 0);
__decorate([
    equipment.Action('saveChanges')
], EquipmentList.prototype, "saveChanges", void 0);
__decorate([
    qualification.State('typeTrigram')
], EquipmentList.prototype, "typeTrigram", void 0);
__decorate([
    equipment.Getter('getEquipmentDamagePositionSuffix')
], EquipmentList.prototype, "getEquipmentDamagePositionSuffix", void 0);
EquipmentList = __decorate([
    Component({
        components: { EquipmentModal, DamageCard },
    })
], EquipmentList);
export default EquipmentList;
