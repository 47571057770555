var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import DamageCard from '@declaration/components/UI/DamageCard.vue';
import ExternalDamageCard from '@declaration/components/externalDamage/ExternalDamageCard.vue';
import MissingDocumentCard from '@declaration/components/receipt/MissingDocumentCard.vue';
import RoomDamageCard from '@declaration/components/roomDamage/RoomDamageCard.vue';
import ReceiptUpload from '@declaration/components/UI/ReceiptUpload.vue';
import { appointmentCompanyExtranetLink, appointmentCompanyExtranetLinkText, appointmentCompanyName, appointmentDateDay, appointmentEndTime, appointmentStartTime, } from '@declaration/services/appointmentLabels';
import { CLAIM_TYPE_IMAGES, CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR } from '@declaration/layout';
import { Step } from '@shared/claim/steps';
import { APPOINTMENT_MODE_TRAD_KEYS } from '@declaration/appointment/labels';
import { CLAIM_TYPE_TRIGRAM_TRAD_KEYS } from '@shared/claim/labels';
import { QUALIFICATION_EQUIPMENT, QUALIFICATION_EQUIPMENT_DETAIL, } from '@shared/constants/equipmentConstants';
import { ROBBERY_TYPE_TRAD_KEYS } from '@shared/constants/robberyConstants';
import { OUTSIDER_INTERVENTION_CHOICES } from '@shared/constants/waterDamageConstants';
import { LEAKAGE_CAUSE_LOCATION_OPTIONS } from '@declaration/constants/waterDamageConstants';
import { QUALIFICATION_ROOM } from '@shared/roomDamage/constants';
import { BreakInDamageRepairQuotePendingStatus, ClaimCompensationOption, ClaimStatus, ClaimTypeTrigram, } from '@shared/types/api/claim';
import { formatDate } from '@shared/utils/date';
import formatAmount from '@shared/utils/formatAmount';
import { EMPTY } from '@shared/utils/strings';
import { forcedAmount } from '@shared/utils/forcedAmount';
const claim = namespace('claim');
const counting = namespace('counting');
const roomDamage = namespace('roomDamage');
const personalProperty = namespace('personalProperty');
const breakInDamage = namespace('breakInDamage');
const externalDamage = namespace('externalDamage');
const contract = namespace('contract');
const qualification = namespace('qualification');
let Summary = class Summary extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.isSaving = false;
        this.stepDeclarationDocumentPending = Step.DeclarationDocumentPending;
        this.formatAmount = formatAmount;
        this.formatDate = formatDate;
        this.forcedAmount = forcedAmount;
        this.compensationTradKey = {
            [ClaimCompensationOption.SelfRepair]: 'claim.records.compensation.type.self_repair',
            [ClaimCompensationOption.PartnerArtisan]: 'claim.records.compensation.type.partner_artisan',
            [ClaimCompensationOption.OtherArtisan]: 'claim.records.compensation.type.other_artisan',
        };
        this.compensationImage = {
            [ClaimCompensationOption.SelfRepair]: require('@declaration/assets/images/compensation/selfRepair.svg'),
            [ClaimCompensationOption.PartnerArtisan]: require('@declaration/assets/images/compensation/partnerArtisan.svg'),
            [ClaimCompensationOption.OtherArtisan]: require('@declaration/assets/images/compensation/otherArtisan.svg'),
        };
        this.invoiceUploading = false;
        this.appointmentImage = require('@declaration/assets/images/appointment/appointmentConfirmed.svg');
        this.appointmentDateDay = appointmentDateDay;
        this.appointmentStartTime = appointmentStartTime;
        this.appointmentEndTime = appointmentEndTime;
        this.appointmentCompanyName = appointmentCompanyName;
        this.appointmentCompanyExtranetLink = appointmentCompanyExtranetLink;
        this.appointmentCompanyExtranetLinkText = appointmentCompanyExtranetLinkText;
    }
    async created() {
        this.isLoading = true;
        await Promise.all([this.fetchCoveringCategoriesIfNotSet()]).catch();
        this.isLoading = false;
    }
    // we use this.$store.state to get the qualification state object and not one of its properties
    // so that qualificationState.qualification type is inferred by qualificationState.typeTrigram
    get qualificationState() {
        return this.$store.state.qualification;
    }
    get shouldDisplayInvoiceUpload() {
        return (this.typeTrigram === ClaimTypeTrigram.WaterDamage &&
            this.claim.chosenCompensationOption === ClaimCompensationOption.OtherArtisan &&
            this.damagesRefund.appliedAmounts.delayed);
    }
    get recordTitle() {
        return this.$tc(this.claim.status === ClaimStatus.Completed
            ? 'claim.records.title'
            : 'claim.records.title.pending');
    }
    get hasInvoice() {
        return !!(this.claim.invoiceFile && this.claim.invoiceFile.id);
    }
    async uploadInvoice(file) {
        this.invoiceUploading = true;
        await this.postAndSaveInvoice(file).catch();
        this.invoiceUploading = false;
    }
    async deleteUploadedInvoice() {
        this.invoiceUploading = true;
        await this.deleteInvoice().catch();
        this.invoiceUploading = false;
    }
    appointmentType(appointment) {
        return this.$tc(APPOINTMENT_MODE_TRAD_KEYS[appointment.type]);
    }
    get qualificationImage() {
        return CLAIM_TYPE_IMAGES[this.qualificationState.typeTrigram];
    }
    get claimDate() {
        return this.claim.damageDate ? formatDate(this.claim.damageDate) : null;
    }
    get claimType() {
        return this.$tc(CLAIM_TYPE_TRIGRAM_TRAD_KEYS[this.qualificationState.typeTrigram]);
    }
    get breakInDamageWithoutPendingDocuments() {
        return this.breakInDamages.filter((damage) => damage.repairQuotePendingStatus === BreakInDamageRepairQuotePendingStatus.None);
    }
    get qualificationInformation() {
        var _a, _b, _c;
        switch (this.qualificationState.typeTrigram) {
            case ClaimTypeTrigram.WaterDamage:
                return [
                    {
                        label: this.$tc('claim.sumup.qualification.location'),
                        value: (_a = this.waterDamageQualificationLocation) !== null && _a !== void 0 ? _a : EMPTY,
                    },
                    {
                        label: this.$tc('claim.sumup.qualification.equipment'),
                        value: (_b = this.waterDamageQualificationEquipment) !== null && _b !== void 0 ? _b : EMPTY,
                    },
                    {
                        label: this.$tc('claim.sumup.qualification.intervention'),
                        value: (_c = this.waterDamageQualificationIntervention) !== null && _c !== void 0 ? _c : EMPTY,
                    },
                ];
            case ClaimTypeTrigram.Robbery:
                return [
                    {
                        label: this.$tc('claim.sumup.qualification.robbery.type'),
                        value: this.qualificationState.qualification.type
                            ? this.$tc(ROBBERY_TYPE_TRAD_KEYS[this.qualificationState.qualification.type])
                            : EMPTY,
                    },
                ];
            default:
                return [];
        }
    }
    get allChangesAreValid() {
        return this.areAllPersonalPropertiesChangesValid && this.areAllBreakInDamagesChangesValid;
    }
    get canContinue() {
        return this.personalPropertiesHavePendingChanges || this.breakInDamagesHavePendingChanges;
    }
    get waterDamageQualificationLocation() {
        if (this.qualificationState.typeTrigram !== ClaimTypeTrigram.WaterDamage) {
            return;
        }
        const qualification = this.qualificationState.qualification;
        const option = this.selectedContract &&
            LEAKAGE_CAUSE_LOCATION_OPTIONS[this.selectedContract.housingType.slug]
            ? LEAKAGE_CAUSE_LOCATION_OPTIONS[this.selectedContract.housingType.slug].find(({ value }) => value === qualification.leakageCauseLocation)
            : null;
        const causeLocationLabel = option && option.tradKey ? this.$tc(option.tradKey) : null;
        if (qualification.leakageCauseRoomSlug === null ||
            !QUALIFICATION_ROOM[qualification.leakageCauseRoomSlug]) {
            return causeLocationLabel;
        }
        const roomLabel = this.$tc(QUALIFICATION_ROOM[qualification.leakageCauseRoomSlug].tradKey);
        return `${causeLocationLabel} - ${roomLabel}`;
    }
    get waterDamageQualificationEquipment() {
        if (this.qualificationState.typeTrigram !== ClaimTypeTrigram.WaterDamage) {
            return;
        }
        const qualification = this.qualificationState.qualification;
        if (qualification.leakageCauseEquipmentSlug === null ||
            !QUALIFICATION_EQUIPMENT[qualification.leakageCauseEquipmentSlug]) {
            return null;
        }
        const equipmentLabel = this.$tc(QUALIFICATION_EQUIPMENT[qualification.leakageCauseEquipmentSlug].tradKey);
        if (qualification.leakageCauseDetailSlug === null ||
            !QUALIFICATION_EQUIPMENT_DETAIL[qualification.leakageCauseDetailSlug]) {
            return equipmentLabel;
        }
        const detailLabel = this.$tc(QUALIFICATION_EQUIPMENT_DETAIL[qualification.leakageCauseDetailSlug].tradKey);
        return `${equipmentLabel} - ${detailLabel}`;
    }
    get waterDamageQualificationIntervention() {
        if (this.qualificationState.typeTrigram !== ClaimTypeTrigram.WaterDamage) {
            return;
        }
        const qualification = this.qualificationState.qualification;
        const option = OUTSIDER_INTERVENTION_CHOICES.find(({ value }) => value === qualification.outsiderInterventionSlug);
        return option && option.tradKey ? this.$tc(option.tradKey) : null;
    }
    validate() {
        this.validatePersonalProperties();
        // Creer nouveau validator
        this.validateBreakInDamages();
    }
    async onContinue() {
        this.validate();
        if (!this.allChangesAreValid) {
            return;
        }
        this.isSaving = true;
        await this.savePersonalPropertiesChanges({ step: Step.DeclarationDocumentPending });
        await this.saveBreakInDamagePropertiesChanges({ step: Step.DeclarationDocumentPending });
        this.isSaving = false;
    }
};
__decorate([
    claim.State('claim')
], Summary.prototype, "claim", void 0);
__decorate([
    qualification.State('typeTrigram')
], Summary.prototype, "typeTrigram", void 0);
__decorate([
    counting.State('counting')
], Summary.prototype, "counting", void 0);
__decorate([
    counting.Getter('damagesRefund')
], Summary.prototype, "damagesRefund", void 0);
__decorate([
    roomDamage.State('damages')
], Summary.prototype, "roomDamages", void 0);
__decorate([
    roomDamage.Action('fetchCoveringCategoriesIfNotSet')
], Summary.prototype, "fetchCoveringCategoriesIfNotSet", void 0);
__decorate([
    personalProperty.State('damages')
], Summary.prototype, "personalProperties", void 0);
__decorate([
    personalProperty.Getter('havePendingChanges')
], Summary.prototype, "personalPropertiesHavePendingChanges", void 0);
__decorate([
    breakInDamage.Getter('havePendingChanges')
], Summary.prototype, "breakInDamagesHavePendingChanges", void 0);
__decorate([
    personalProperty.Getter('areDamagesValid')
], Summary.prototype, "areAllPersonalPropertiesChangesValid", void 0);
__decorate([
    breakInDamage.Getter('areDamagesValid')
], Summary.prototype, "areAllBreakInDamagesChangesValid", void 0);
__decorate([
    personalProperty.Action('saveChanges')
], Summary.prototype, "savePersonalPropertiesChanges", void 0);
__decorate([
    breakInDamage.Action('saveChanges')
], Summary.prototype, "saveBreakInDamagePropertiesChanges", void 0);
__decorate([
    personalProperty.Action('validateAndRevealDamages')
], Summary.prototype, "validatePersonalProperties", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], Summary.prototype, "validateBreakInDamages", void 0);
__decorate([
    externalDamage.State('damages')
], Summary.prototype, "externalDamages", void 0);
__decorate([
    breakInDamage.State('damages')
], Summary.prototype, "breakInDamages", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], Summary.prototype, "updateAndSaveClaim", void 0);
__decorate([
    claim.Action('postAndSaveInvoice')
], Summary.prototype, "postAndSaveInvoice", void 0);
__decorate([
    claim.Action('deleteInvoice')
], Summary.prototype, "deleteInvoice", void 0);
__decorate([
    contract.State('selectedContract')
], Summary.prototype, "selectedContract", void 0);
Summary = __decorate([
    Component({
        components: {
            DamageCard,
            UiButton,
            RoomDamageCard,
            ExternalDamageCard,
            ReceiptUpload,
            MissingDocumentCard,
        },
        meta: {
            title: 'track.details.breadcrumb',
            color: CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR,
        },
    })
], Summary);
export default Summary;
