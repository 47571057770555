var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { UiButton } from '@claims-ia/design-system';
import Modal from '@declaration/components/UI/Modal.vue';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
let OtherArtisanConfirmationModal = class OtherArtisanConfirmationModal extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.headerImage = require('@declaration/assets/images/miscellaneous/idea.svg');
    }
    close() {
        this.$emit('close');
    }
    async confirm() {
        this.loading = true;
        await this.chooseOtherArtisanOption();
        this.loading = false;
    }
    cancel() {
        this.close();
        this.choosePartnerArtisanOption();
    }
};
OtherArtisanConfirmationModal = __decorate([
    Component({
        components: { UiButton, Modal },
        meta: {
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
        },
        props: {
            isDisplayed: { type: Boolean, default: false },
        },
        inject: ['chooseOtherArtisanOption', 'choosePartnerArtisanOption'],
    })
], OtherArtisanConfirmationModal);
export default OtherArtisanConfirmationModal;
