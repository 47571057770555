var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { ExternalDamageComponent } from './ExternalDamageComponent';
import DamageCard from '@declaration/components/UI/DamageCard.vue';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import ExternalDamageModal from '@declaration/components/externalDamage/ExternalDamageModal.vue';
import ExternalDamageCard from '@declaration/components/externalDamage/ExternalDamageCard.vue';
import { EXTERNAL_DAMAGES_QUESTION_TRAD_KEYS, } from '@declaration/types/externalDamage';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
import { externalDamageFields, externalDamageTitle, } from '@shared/externalDamage/externalDamageFields';
import { ExclusionReason } from '@shared/types/api/refundDetail';
const externalDamage = namespace('externalDamage');
const qualification = namespace('qualification');
let ExternalDamageList = class ExternalDamageList extends ExternalDamageComponent {
    constructor() {
        super(...arguments);
        this.isExternalDamageModalOpen = false;
        this.selectedchangeKey = null;
        this.cardSaving = false;
    }
    get tradKeys() {
        return EXTERNAL_DAMAGES_QUESTION_TRAD_KEYS;
    }
    isExcludedByGuarantees(damage) {
        return damage.exclusionReason === ExclusionReason.ContractGuarantees;
    }
    modelFields(damage) {
        // Broken external damage typing
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return externalDamageFields(damage, {
            edition: false,
            type: this.typeTrigram,
            projection: ModelFieldsProjection.Declaration,
            translate: this.$tc.bind(this),
            stormSnowHailDamageCauseType: 'causeType' in this.qualification ? this.qualification.causeType : null,
        });
    }
    selectItemAndOpenHandler(damage) {
        if (this.selectItemAndOpenModal !== null) {
            this.selectItemAndOpenModal(damage);
        }
    }
    deleteItemHandler(damage) {
        this.$emit('on-delete-damage', damage);
    }
    addNewExternalDamage() {
        this.selectedchangeKey = null;
        this.isExternalDamageModalOpen = true;
    }
    isComplete(damage) {
        return !!damage.id;
    }
    isStormSnowHailDamage(qualification) {
        return typeof qualification === 'object' && this.typeTrigram === ClaimTypeTrigram.StormSnowHail;
    }
    isFireDamage(qualification) {
        return typeof qualification === 'object' && this.typeTrigram === ClaimTypeTrigram.Fire;
    }
    get damageOrigin() {
        const qualification = this.qualification;
        if (this.isStormSnowHailDamage(qualification))
            return qualification.causeType;
        if (this.isFireDamage(qualification))
            return ClaimTypeTrigram.Fire;
        return null;
    }
    addNewDamageHandler() {
        this.$emit('modale-opened');
    }
    title(damage, index) {
        const positionSuffix = this.getExternalDamagePositionSuffix(damage.slug, index);
        return (externalDamageTitle(damage, this.damageOrigin, this.$tc.bind(this)) +
            (positionSuffix ? ` ${positionSuffix}` : ''));
    }
};
__decorate([
    externalDamage.Getter('changedDamages')
], ExternalDamageList.prototype, "externalDamages", void 0);
__decorate([
    externalDamage.Getter('getExternalDamagePositionSuffix')
], ExternalDamageList.prototype, "getExternalDamagePositionSuffix", void 0);
__decorate([
    qualification.State('qualification')
], ExternalDamageList.prototype, "qualification", void 0);
__decorate([
    qualification.State('typeTrigram')
], ExternalDamageList.prototype, "typeTrigram", void 0);
ExternalDamageList = __decorate([
    Component({
        components: { ExternalDamageModal, ExternalDamageCard, DamageCard, UiButton },
        props: {
            selectItemAndOpenModal: {
                type: Function,
                default: null,
            },
            addButtonOutlined: {
                type: Boolean,
                default: false,
            },
        },
    })
], ExternalDamageList);
export default ExternalDamageList;
