var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import DamageCard from '@declaration/components/UI/DamageCard.vue';
import InformationModal from '@declaration/components/InformationModal.vue';
import BreakInDamageModal from '@declaration/components/breakInDamage/BreakInDamageModal.vue';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { breakInDamageTitle, breakInDamageFields } from '@shared/breakInDamage/breakInDamageFields';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
const contract = namespace('contract');
const breakInDamage = namespace('breakInDamage');
const qualification = namespace('qualification');
let BreakInDamageList = class BreakInDamageList extends Vue {
    constructor() {
        super(...arguments);
        this.isBreakInDamageModalOpen = false;
        this.isInformationModalOpen = false;
        this.selectedchangeKey = null;
        this.alreadyOpened = false;
    }
    get modalTitle() {
        return this.$tc('claim.declaration.section.break_in_damages.information.modal.title');
    }
    get modalText() {
        return this.$tc('claim.declaration.section.break_in_damages.information.modal.text');
    }
    get insurerHasFeatureInformationModalBreakInRobberyThreshold() {
        return insurerHasFeature(InsurerFeatureFlag.InformationModalBreakInRobberyThreshold);
    }
    selectBreakInDamageAndOpenModal(breakInDamage) {
        if (breakInDamage.id) {
            // Existing damage, change key is IRI
            this.selectedchangeKey = breakInDamage.id;
        }
        else {
            this.selectedchangeKey = null;
        }
        this.isBreakInDamageModalOpen = true;
    }
    addNewBreakInDamage() {
        this.selectedchangeKey = 0;
        this.isBreakInDamageModalOpen = true;
    }
    closeInformationModal() {
        this.isInformationModalOpen = false;
        this.alreadyOpened = true;
        this.addNewBreakInDamage();
    }
    closeModal() {
        this.isBreakInDamageModalOpen = false;
        this.isInformationModalOpen = false;
        this.selectedchangeKey = null;
    }
    confirmDelete(breakInDamageIri, deleteCallback) {
        if (breakInDamageIri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc('claim.damage.break_in_damages.form.delete.modal.text'),
                cancelText: this.$tc('claim.damage.break_in_damages.form.delete.modal.cancel'),
                confirmText: this.$tc('claim.damage.break_in_damages.form.delete.modal.confirm'),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    warningLOCmessage() {
        if (this.isTenant === true &&
            this.alreadyOpened === false &&
            this.insurerHasFeatureInformationModalBreakInRobberyThreshold) {
            this.isInformationModalOpen = true;
        }
        else {
            this.addNewBreakInDamage();
        }
    }
    selectItemAndOpenHandler(damage) {
        if (this.selectItemAndOpenModal !== null) {
            this.selectItemAndOpenModal(damage);
        }
    }
    isComplete(damage) {
        return !!damage.id;
    }
    modelFields(damage) {
        return breakInDamageFields(damage, {
            edition: false,
            projection: ModelFieldsProjection.Declaration,
            translate: this.$tc.bind(this),
            type: this.typeTrigram,
        });
    }
    deleteDamageHandler(breakInDamage) {
        this.damage = breakInDamage;
        this.confirmDelete(breakInDamage.id, this.deleteBreakInDamage);
    }
    async deleteBreakInDamage() {
        await this.delete(this.damage.id);
        await this.saveChanges();
    }
    title(damage, index) {
        const positionSuffix = this.getBreakInDamagePositionSuffix(damage.type, index);
        return (breakInDamageTitle(damage, this.$tc.bind(this)) + (positionSuffix ? ` ${positionSuffix}` : ''));
    }
};
__decorate([
    breakInDamage.Action('setBreakInDamageInEditionIndex')
], BreakInDamageList.prototype, "setBreakInDamageInEditionIndex", void 0);
__decorate([
    breakInDamage.State('damages')
], BreakInDamageList.prototype, "breakInDamages", void 0);
__decorate([
    breakInDamage.Action('deleteDamage')
], BreakInDamageList.prototype, "delete", void 0);
__decorate([
    breakInDamage.Action('saveChanges')
], BreakInDamageList.prototype, "saveChanges", void 0);
__decorate([
    breakInDamage.Getter('getBreakInDamagePositionSuffix')
], BreakInDamageList.prototype, "getBreakInDamagePositionSuffix", void 0);
__decorate([
    contract.Getter('isTenant')
], BreakInDamageList.prototype, "isTenant", void 0);
__decorate([
    qualification.State('typeTrigram')
], BreakInDamageList.prototype, "typeTrigram", void 0);
BreakInDamageList = __decorate([
    Component({
        components: { BreakInDamageModal, DamageCard, InformationModal },
        props: {
            addButtonOutlined: {
                type: Boolean,
                default: false,
            },
            selectItemAndOpenModal: {
                type: Function,
                default: null,
            },
        },
    })
], BreakInDamageList);
export default BreakInDamageList;
