var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import DamageFields from '@declaration/components/damage/DamageFields.vue';
import RepairQuoteUpload from '@declaration/components/upload/RepairQuoteUpload.vue';
import { RepairableStatus } from '@shared/types/api/personalProperty';
import { ClaimFileType } from '@shared/types/file';
import { isPersonalProperty } from '@shared/damage/damage';
const claim = namespace('claim');
const personalProperty = namespace('personalProperty');
const qualification = namespace('qualification');
const layout = namespace('layout');
const pages = namespace('pages');
let DamageCard = class DamageCard extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.ClaimFileType = ClaimFileType;
        this.RepairableStatus = RepairableStatus;
    }
    get repairableStatus() {
        if ('repairableStatus' in this.damage) {
            return this.damage.repairableStatus;
        }
        else
            return '';
    }
    get repairFileCollection() {
        if ('repairFileCollection' in this.damage) {
            return this.damage.repairFileCollection;
        }
        else
            return {};
    }
    get repairQuoteInfo() {
        if ('repairQuoteInfo' in this.damage) {
            return this.damage.repairQuoteInfo;
        }
        else
            return {};
    }
    get shouldDisplayRepairQuoteForm() {
        return this.canUploadRepairFile;
    }
    get isComplete() {
        if (this.damage && isPersonalProperty(this.damage)) {
            return this.isDamageValid(this.damage) && this.damage.id;
        }
        return !!this.damage.id;
    }
    onRepairQuoteUpdate({ repairQuoteInfo, repairFileCollection, repairableStatus, }) {
        this.updatePersonalProperty({
            changeKey: this.changeKey,
            data: {
                repairableStatus,
                repairFileCollection,
                repairQuoteInfo,
            },
        });
    }
};
__decorate([
    claim.State('claim')
], DamageCard.prototype, "claim", void 0);
__decorate([
    personalProperty.State('damages')
], DamageCard.prototype, "personalProperties", void 0);
__decorate([
    personalProperty.State('categories')
], DamageCard.prototype, "categories", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], DamageCard.prototype, "categoriesByIri", void 0);
__decorate([
    personalProperty.Action('deleteDamage')
], DamageCard.prototype, "delete", void 0);
__decorate([
    personalProperty.Getter('isDamageValid')
], DamageCard.prototype, "isDamageValid", void 0);
__decorate([
    personalProperty.Action('deletePlaceholder')
], DamageCard.prototype, "deletePlaceholder", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], DamageCard.prototype, "updatePersonalProperty", void 0);
__decorate([
    personalProperty.Action('saveChanges')
], DamageCard.prototype, "saveChanges", void 0);
__decorate([
    personalProperty.Getter('validation')
], DamageCard.prototype, "validationGetter", void 0);
__decorate([
    personalProperty.Getter('change')
], DamageCard.prototype, "changeGetter", void 0);
__decorate([
    pages.State('activeStep')
], DamageCard.prototype, "activeStep", void 0);
__decorate([
    qualification.State('typeTrigram')
], DamageCard.prototype, "typeTrigram", void 0);
__decorate([
    layout.State('color')
], DamageCard.prototype, "color", void 0);
DamageCard = __decorate([
    Component({
        props: {
            damage: {
                type: Object,
                required: true,
            },
            damageType: {
                type: String,
                default: null,
            },
            index: {
                type: Number,
                required: true,
            },
            changeKey: { type: [Number, String], default: null },
            canUploadRepairFile: {
                type: Boolean,
                default: false,
            },
            confirmDelete: {
                type: Function,
                default: null,
            },
            currentStep: {
                type: String,
                default: null,
            },
            canUploadDocuments: {
                type: Boolean,
                default: false,
            },
            canEdit: {
                type: Boolean,
                default: false,
            },
            isExcludedByGuarantees: {
                type: Boolean,
                default: false,
            },
            shouldDisplayRepairQuoteInfo: {
                type: Boolean,
                default: false,
            },
            isWaitingForRepairFile: {
                type: Boolean,
                default: false,
            },
            tradKeys: {
                type: Object,
                default: null,
            },
            waitingForRepairFileWarning: {
                type: String,
                default: null,
            },
            onRepairQuoteOptout: {
                type: Function,
                default: null,
            },
            isSaving: {
                type: Boolean,
                default: false,
            },
            damageOrigin: {
                type: String,
                default: null,
            },
            title: {
                default: '',
            },
            modelFields: {
                type: Object,
            },
        },
        components: {
            DamageFields,
            RepairQuoteUpload,
            UiButton,
        },
    })
], DamageCard);
export default DamageCard;
