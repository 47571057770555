var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import Card from '@declaration/components/UI/Card.vue';
import DocumentSynthesisList from '@declaration/components/documents/DocumentSynthesisList.vue';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { MandatoryDocumentStatus } from '@shared/types/documents';
const claim = namespace('claim');
const documents = namespace('documents');
const helpers = namespace('helpers');
let ClaimDateQuestion = class ClaimDateQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.errorMessage = null;
        this.isFetching = false;
        this.isSaving = false;
    }
    mounted() {
        this.isFetching = true;
        this.errorMessage = null;
        this.fetchDocuments().finally(() => {
            this.isFetching = false;
        });
    }
    beforeDestroy() {
        this.stopPolling();
    }
    get validDocuments() {
        return this.documentByStatus(MandatoryDocumentStatus.Uploaded, MandatoryDocumentStatus.Valid);
    }
    get invalidDocuments() {
        return this.documentByStatus(MandatoryDocumentStatus.Invalid, MandatoryDocumentStatus.Missing);
    }
    get notVerifiedDocuments() {
        return this.documentByStatus(MandatoryDocumentStatus.NotVerified);
    }
    get pendingDocuments() {
        return this.documentByStatus(MandatoryDocumentStatus.Pending);
    }
    get providedDocuments() {
        return [...this.validDocuments, ...this.notVerifiedDocuments];
    }
    get missingDocuments() {
        return [...this.pendingDocuments, ...this.invalidDocuments];
    }
    showGlobalHelp() {
        this.showHelp(this.step);
    }
    get questionTitle() {
        return this.$tc('claim.document_synthesis.question.title');
    }
    get answer() {
        return this.$tc('claim.document_synthesis.question.answer');
    }
    get title() {
        return this.$tc('claim.document_synthesis.title');
    }
    get headingImage() {
        return require('@declaration/assets/images/documents/checklist.svg');
    }
    get headingTitle() {
        return this.$tc('claim.document_synthesis.heading.title');
    }
    get headingDescription() {
        return this.isAnalysisEnabled
            ? this.$tc('claim.document_synthesis.heading.description.analysis')
            : this.$tc('claim.document_synthesis.heading.description');
    }
    get headingContent() {
        const content = [
            {
                icon: 'check',
                label: this.isAnalysisEnabled
                    ? this.$tc('claim.document_synthesis.heading.valid')
                    : this.$tc('claim.document_synthesis.heading.uploaded'),
                count: this.providedDocuments.length,
                status: MandatoryDocumentStatus.Uploaded,
            },
            {
                icon: 'cancel',
                label: this.isAnalysisEnabled
                    ? this.$tc('claim.document_synthesis.heading.invalid')
                    : this.$tc('claim.document_synthesis.heading.missing'),
                count: this.invalidDocuments.length,
                status: MandatoryDocumentStatus.Missing,
            },
        ];
        if (this.isAnalysisEnabled) {
            content.push({
                icon: 'pending',
                label: this.$tc('claim.document_synthesis.heading.pending'),
                count: this.pendingDocuments.length,
                status: MandatoryDocumentStatus.Pending,
            });
        }
        return content;
    }
    get missingDocumentsTitle() {
        return this.isAnalysisEnabled
            ? this.$tc('claim.document_synthesis.invalid.title')
            : this.$tc('claim.document_synthesis.missing.title');
    }
    get providedDocumentsTitle() {
        return this.isAnalysisEnabled
            ? this.$tc('claim.document_synthesis.valid.title')
            : this.$tc('claim.document_synthesis.uploaded.title');
    }
    get validationLabel() {
        return this.$tc('claim.document_synthesis.validation.button');
    }
    get helpLabel() {
        return this.$tc('help.default.question');
    }
    get isValid() {
        return this.missingDocuments.length === 0;
    }
    get isLoading() {
        return this.isUpdatingClaim || this.isUploading;
    }
    displayError() {
        if (this.isValid) {
            this.errorMessage = null;
            return;
        }
        if (this.missingDocuments.length > 0) {
            this.errorMessage = this.$tc('claim.document_synthesis.validation.error.missing');
        }
    }
    async save() {
        try {
            this.isSaving = true;
            await this.updateAndSaveClaimStep(this.step);
        }
        finally {
            this.isSaving = false;
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], ClaimDateQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], ClaimDateQuestion.prototype, "updateAndSaveClaimStep", void 0);
__decorate([
    helpers.State('stepHelps')
], ClaimDateQuestion.prototype, "helpState", void 0);
__decorate([
    helpers.Action('show')
], ClaimDateQuestion.prototype, "showHelp", void 0);
__decorate([
    documents.State('isUploading')
], ClaimDateQuestion.prototype, "isUploading", void 0);
__decorate([
    documents.Getter('isAnalysisEnabled')
], ClaimDateQuestion.prototype, "isAnalysisEnabled", void 0);
__decorate([
    documents.Getter('documentByStatus')
], ClaimDateQuestion.prototype, "documentByStatus", void 0);
__decorate([
    documents.Action('fetchDocuments')
], ClaimDateQuestion.prototype, "fetchDocuments", void 0);
__decorate([
    documents.Action('stopPolling')
], ClaimDateQuestion.prototype, "stopPolling", void 0);
ClaimDateQuestion = __decorate([
    Component({
        components: { QuestionPanel, Card, DocumentSynthesisList, UiButton },
        props: QuestionProps,
    })
], ClaimDateQuestion);
export default ClaimDateQuestion;
