var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR } from '@declaration/layout';
import { Step } from '@shared/claim/steps';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import SecondaryButton from '@declaration/components/UI/SecondaryButton.vue';
const claim = namespace('claim');
let ExpertAppointmentByVisioVerification = class ExpertAppointmentByVisioVerification extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.imgVisioSrc = require('@declaration/assets/images/appointment/expertByVisio.svg');
        this.imgVisioAlt = 'Expert par visio';
    }
    paragraphs() {
        return [
            this.$tc('appointment.expert.visio.verification.description.text.1'),
            this.$tc('appointment.expert.visio.verification.description.text.2'),
            this.$tc('appointment.expert.visio.verification.description.text.3'),
        ];
    }
    async chooseVisioConferenceMode(isVisioConferenceAccepted) {
        this.isLoading = true;
        await this.updateAndSaveClaim({
            step: Step.ExpertAppointmentByVisioVerification,
            claimPayload: { isVisioConferenceAccepted },
        });
        this.isLoading = false;
    }
    get outputVideoExpertise() {
        return insurerHasFeature(InsurerFeatureFlag.OutputVideoExpertise);
    }
};
__decorate([
    claim.Action('updateAndSaveClaim')
], ExpertAppointmentByVisioVerification.prototype, "updateAndSaveClaim", void 0);
ExpertAppointmentByVisioVerification = __decorate([
    Component({
        components: {
            UiButton,
            SecondaryButton,
        },
        meta: {
            title: 'appointment.expert.date_selection.breadcrumb_title',
            color: CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], ExpertAppointmentByVisioVerification);
export default ExpertAppointmentByVisioVerification;
